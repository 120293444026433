import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import Stepone from '../components/FormStepone';
import Layout from '../components/layout';
import { ResetCSS } from '../common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import '../bootstrap/dist/css/bootstrap.min.css';

const OfferteAanvragen = () => {

  const Data = useStaticQuery(graphql`
    query {
      allContentfulForm {
        nodes {
          id
          formPageMetaTitle
          metaKeywords
          metaDescription
          step2Active
          step3Active
          step4Active
          step5Active
          step6Active
          step6ButtonText
          step3TextPlaceholder
          step2SlugUrl
          step3SlugUrl
          step4SlugUrl
          step5SlugUrl
          step6SlugUrl
          step2MultipleOptions {
            optionLabel {
              json
            }
            optionValue
          }
          step1Description {
            json
          }
          redirectUrlOnSuccess
          logo {
            fluid(maxWidth: 200){
              ...GatsbyContentfulFluid_withWebp
            }
          }
          step1MultipleOptions {
            optionLabel {
              json
            }
            optionValue
          }
          step2Description {
            json
          }
          step3Description {
            json
          }
          step4MultipleOptions {
            optionLabel {
              json
            }
            optionValue
          }
          step4Description {
            json
          }
          step5Text {
            json
          }
          sideBarTitle
          sideBarLogos {
            fluid(maxWidth: 70){
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }

    }
  `);

  const formData = Data.allContentfulForm.nodes[0];

  return (
    <>
      <SEO
          title={formData.formPageMetaTitle}
          description={formData.metaDescription}
        />
        <ResetCSS />
        <GlobalStyle />
        <div className="form_container anvragen_page">
        <Stepone formData={formData} />
      </div>
      
    </>
  );

}

export default OfferteAanvragen;
